.card1 {
    display: flex;
    height: 100%;
    // min-width: 400px;
    width: 400px;
    flex-direction: column;
    justify-content: space-between;
  }
  
  .card-content {
    width: 100%;
    text-align: left
  }
  .pointer-cursor {
    cursor: pointer;
  }
.sidebar {
  width: 240px;
  height: 100vh;
  position: fixed;
  top: 65px;
  z-index: 100;
  background: #f0f2fc;
  // background: linear-gradient(0deg, #fdfbff, #fdfbff),
  //   linear-gradient(0deg, rgba(0, 89, 199, 0.05), rgba(0, 89, 199, 0.05));
  box-shadow: 0px 1px 3px 1px #00000026;
  box-shadow: 0px 1px 2px 0px #0000004d;
  border-radius: 0px 0px 16px 0px;
  padding: 12px;
  border-radius: 0px 0px 16px 0px;

  .sidebar-link {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    border-radius: 100px;
    gap: 12px;
    outline: none;
    width: 216px;
    height: 56px;
    text-decoration: none;
    &:hover {
      background-color: #dbe2f9;
      text-decoration: none;
    }
  }

  .sidebar-button {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    border-radius: 100px;
    gap: 12px;
    background-color: transparent;
    border: none;
    outline: none;
    width: 216px;
    height: 56px;
    font-size: 14px;
    font-weight: 500;
    line-height: 20px;
    letter-spacing: 0.10000000149011612px;
    text-align: left;
    color: #44464e;
    &:hover {
      background-color: transparent;
    }

    .sidebar-icon {
      width: 24px;
      height: 24px;
    }
  }
  .sidebar-line {
    border: none;
    border-top: 1px solid #75777f;
    margin: 8px 0;
  }
  .active {
    background-color: #dbe2f9;
    color: black;
  }
}


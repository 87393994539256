@import url("https://fonts.googleapis.com/css2?family=Roboto:wght@300;400;500;700&display=swap");

.logo {
  width: 40px;
  height: 40px;
  align-items: center;
  justify-content: center;
  display: flex;
}

.error-message {
  color: red;
  font-size: 12px;
  margin-top: 2px;
  text-align: center;
}

.error-message-signin {
  color: red;
  font-size: 12px;
  margin-top: -18px;
  text-align: center;
}

.error-message-reset {
  color: red;
  font-size: 12px;
  margin-top: -2px;
  text-align: center;
}

.error-reset-password-message {
  color: red;
  font-size: 12px;
  margin-bottom: -18px;
  text-align: center;
}

.dropdown-show {
  padding: 6px 8px 6px 12px;
  border-radius: 8px;
  gap: 8px;
  color: #75777f;
  border: 1px solid #75777f;
  display: inline-block;
  background-color: #0159c7;
}

.dropdown-btn {
  text-decoration: none;
  color: white;
}

.avatar-container {
  width: 40px;
  height: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
}

.custom-avatar {
  font-size: 16px;
  font-weight: 500;
  line-height: 24px;
  letter-spacing: 0.1px;
  text-align: center;
  color: white;
}

.wrapper-all {
  width: 400px;
  height: 396px;
  top: 160px;
  left: 520px;
  border-radius: 12px;
  gap: 16px;

  box-shadow: 0px 4px 4px 0px #0000004d;
  box-shadow: 0px 8px 12px 6px #00000026;

  margin: 80px auto;
  padding: 40px 30px 30px 30px;
}

.support-wrapper-all {
  height: 576px;
  top: 160px;
  left: 520px;
  border-radius: 12px;
  gap: 16px;

  box-shadow: 0px 4px 4px 0px #0000004d;
  box-shadow: 0px 8px 12px 6px #00000026;

  margin: 80px auto;
  padding: 40px 30px 30px 30px;
}

.password-reset-title {
  width: 117px;
  height: 24;
  font-weight: 500;
}

.password-reset-title2 {
  width: 300px;
  height: 24;
}

.mail-show {
  color: black !important;
}

.material-icon {
  color: #6d6060;
  margin-right: 2%;
}

.button-container {
  display: flex;
  justify-content: flex-end;
  align-items: center;
}

.button-container-reset {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.button-container-support {
  display: flex;
  justify-content: space-between;
}

.responsive-button {
  padding: 10px 20px;
  font-size: 16px;
}

.verification-code-container {
  display: flex;
  align-items: center;
  margin-bottom: 10px;

  .key-icon {
    width: 24px;
    height: 24px;
    margin-right: 8px;
  }

  .digit-input {
    width: 50px;
    text-align: center;
  }

  input[type="text"] {
    width: 100%;
    max-width: 50px;
    text-align: center;
    border: 1px solid #ccc;
    border-radius: 4px;
    padding: 8px;
    font-size: 14px;
  }
}

.first-half {
  width: 332px;
  height: 148px;
  padding: 16px 0px;
  gap: 24px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.first-half2 {
  width: 332px;
  height: 148px;
  padding: 16px 0px;
  gap: 24px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-bottom: 36px;
}

.first-half-reset-password {
  width: 332px;
  height: 107px;
  padding: 16px 0px;
  gap: 24px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.title {
  width: 80px;
  height: 24px;
  font-size: 16px;
  font-weight: 500;
  line-height: 24px;
  letter-spacing: 0.1px;
  text-align: center;
  color: #1b1b1e;
  background-color: #ffffff;
}

.title {
  width: 50px;
  height: 20px;
  font-size: 16px;
  font-weight: 500;
  line-height: 24px;
  letter-spacing: 0.1px;
  text-align: center;
  color: #1b1b1e;
  background-color: #ffffff;
}

.signin-title {
  width: 55px;
  height: 20px;
  font-size: 16px;
  font-weight: 500;
  line-height: 24px;
  letter-spacing: 0.1px;
  text-align: center;
  color: #1b1b1e;
  background-color: #ffffff;
}

.verify-title {
  width: 131px;
  height: 20px;
  font-size: 16px;
  font-weight: 500;
  line-height: 24px;
  letter-spacing: 0.1px;
  text-align: center;
  color: #1b1b1e;
  background-color: #ffffff;
}

.password-title {
  width: 120px;
  height: 24px;
  font-size: 16px;
  font-weight: 500;
  line-height: 24px;
  letter-spacing: 0.1px;
  text-align: center;
  color: #1b1b1e;
  background-color: #ffffff;
}

.support-password-title {
  width: 180px;
  height: 24px;
  font-size: 16px;
  font-weight: 500;
  line-height: 24px;
  letter-spacing: 0.1px;
  text-align: center;
  color: #1b1b1e;
  background-color: #ffffff;
}

.title-body {
  margin-bottom: 30px;
  width: 273px;
  height: 20px;
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  letter-spacing: 0.25px;
  text-align: center;
  color: #1b1b1e;
  background-color: #ffffff;
}

.verify-title-body {
  margin-bottom: 30px;
  width: 282px;
  height: 20px;
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  letter-spacing: 0.25px;
  text-align: center;
  color: #1b1b1e;
  background-color: #ffffff;
}

.support-title-body {
  margin-bottom: 30px;
  width: 320px;
  height: 20px;
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  letter-spacing: 0.25px;
  text-align: center;
  color: #1b1b1e;
  background-color: #ffffff;
}

.field {
  display: flex;
  align-items: center;
  width: 340px;
  height: 56px;
  padding: 0px 14px;
  border-radius: 4px;
  border: 1px solid #75777f;
  margin-bottom: 20px;
  // gap: 12px;

  input {
    flex: 1;
    font-family: inherit;
    font-size: 16px;
    font-weight: 400;
    line-height: 24px;
    letter-spacing: 0.1px;
    outline: none;
    border: none;
    background: none;
    padding: 10px 15px 10px 10px;
  }
}

.errorfield {
  display: flex;
  align-items: center;
  width: 340px;
  height: 56px;
  padding: 0px 14px;
  border-radius: 4px;
  border: 1px solid red;
  margin-bottom: 20px;
  // gap: 12px;

  input {
    flex: 1;
    font-family: inherit;
    font-size: 16px;
    font-weight: 400;
    line-height: 24px;
    letter-spacing: 0.1px;
    outline: none;
    border: none;
    background: none;
    padding: 10px 15px 10px 10px;
  }
}

.support-field {
  display: flex;
  align-items: center;
  width: 355px;
  height: 56px;
  padding: 0px 14px;
  border-radius: 4px;
  border: 1px solid #75777f;
  margin-bottom: 20px;
  // gap: 12px;

  input {
    flex: 1;
    font-family: inherit;
    font-size: 16px;
    font-weight: 400;
    line-height: 24px;
    letter-spacing: 0.1px;
    outline: none;
    border: none;
    background: none;
    padding: 10px 15px 10px 10px;
  }
}

.message-field {
  display: flex;
  align-items: center;
  width: 355px;
  height: 100px;
  padding: 0px 14px;
  border-radius: 4px;
  border: 1px solid #75777f;
  margin-bottom: 20px;
  // gap: 12px;

  input {
    flex: 1;
    font-family: inherit;
    font-size: 16px;
    font-weight: 400;
    line-height: 24px;
    letter-spacing: 0.1px;
    outline: none;
    border: none;
    background: none;
    padding: 10px 15px 10px 10px;
  }
}

.bottom {
  display: flex;
  width: 360px;
  height: fit-content;
  padding: 16px 4px;
  gap: 16px;
  align-items: center;
  justify-content: center;
}

.new-bottom {
  margin-top: 20px;
  display: flex;
  width: 360px;
  height: fit-content;
  padding: 16px 4px;
  gap: 16px;
  align-items: center;
  justify-content: center;
}

.new-verify-bottom {
  margin-top: 0px;
  display: flex;
  width: 360px;
  height: fit-content;
  padding: 16px 4px;
  gap: 16px;
  align-items: center;
  justify-content: center;
}

.new-reset-bottom {
  margin-top: 40px;
  display: flex;
  width: 360px;
  height: fit-content;
  padding: 16px 4px;
  gap: 16px;
  align-items: center;
  justify-content: center;
}

.button-common {
  width: 106px;
  height: 40px;
  padding: 10px 24px;
  border-radius: 100px;
  border: none;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 8px;
  color: #fff;
  font-size: 14px;
  font-weight: 500;
  line-height: 20px;
  letter-spacing: 0.1px;
  text-align: center;
  cursor: pointer;

  background: #0059c7;
}

.button {
  font-size: 14px;
  font-weight: 500;
  line-height: 20px;
  letter-spacing: 0.1px;
  color: #fff;
  cursor: pointer;
}

.help {
  width: 54px;
  height: 40px;
  padding: 10px 12px;
  border-radius: 100px;
  gap: 8px;
  color: #725574;
  background-color: #fff;
  font-size: 14px;
  font-weight: 500;
  line-height: 20px;
  letter-spacing: 0.1px;
  text-align: center;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
}

.privacy {
  width: 71;
  height: 40px;
  padding: 10px 12px;
  border-radius: 100px;
  gap: 8px;
  color: #725574;
  background-color: #fff;
  font-size: 14px;
  font-weight: 500;
  line-height: 20px;
  letter-spacing: 0.1px;
  text-align: center;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
}

.terms {
  width: 65px;
  height: 40px;
  padding: 10px 12px;
  border-radius: 100px;
  gap: 8px;
  color: #725574;
  background-color: #fff;
  font-size: 14px;
  font-weight: 500;
  line-height: 20px;
  letter-spacing: 0.1px;
  text-align: center;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
}

.button-container-password {
  display: flex;
  justify-content: flex-end;
}

.back-button {
 
  padding-left: 10px;
  height: 40px;

  border-radius: 100px;
  border: none;

  justify-content: left;
  align-items: left;
  gap: 8px;
  color: #0059c7;
  font-size: 14px;
  font-weight: 500;
  line-height: 20px;
  letter-spacing: 0.1px;
  text-align: left;
  cursor: pointer;
  background: none;
  margin-right: 35px;
}

.forgot-button {
  width: 200px;
  height: 40px;

  border-radius: 100px;
  border: none;

  justify-content: left;
  align-items: left;
  gap: 8px;
  color: #0059c7;
  font-size: 14px;
  font-weight: 500;
  line-height: 20px;
  letter-spacing: 0.1px;
  text-align: left;
  cursor: pointer;
  background: none;
  margin-right: 25px;
}

.links {
  text-decoration: none;
  color: black;
  padding-right: 5px;
}

.continue-button {
  width: 106px;
  height: 40px;
  padding: 10px 24px;
  border-radius: 100px;
  border: none;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 8px;
  color: #fff;
  font-size: 14px;
  font-weight: 500;
  line-height: 20px;
  letter-spacing: 0.1px;
  text-align: center;
  cursor: pointer;

  background: #0059c7;
}

.continue-button-verify {
  width: 106px;
  height: 40px;
  padding: 10px 24px;
  border-radius: 100px;
  border: none;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 8px;
  color: #fff;
  font-size: 14px;
  font-weight: 500;
  line-height: 20px;
  letter-spacing: 0.1px;
  text-align: center;
  cursor: pointer;

  background: #0059c7;
  margin-top: 20px;
}

.support-button {
  width: 176px;
  height: 40px;
  padding: 10px 24px;
  border-radius: 100px;
  border: none;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 8px;
  color: #fff;
  font-size: 14px;
  font-weight: 700;
  line-height: 20px;
  letter-spacing: 0.1px;
  text-align: center;
  cursor: pointer;

  background: #0059c7;
}

.backIconStyling:hover {
  color: #000000;
}

@media (max-width: 380px) {
  .wrapper {
    padding: 40px 15px 15px 15px;
  }
}

@media (max-width: 425px) {
  .wrapper {
    max-width: 80%;
  }
}

@media (max-width: 480px) {
  .button-container {
    flex-direction: column;
    height: auto;
  }
}

@media (max-width: 480px) {
  .new-bottom {
    margin-top: -10px;
    margin-left: -33px;
  }
}

@media (max-width: 414px) {
  .new-bottom {
    margin-top: -21px;
    margin-left: -33px;
  }
}

@media (max-width: 600px) {
  .wrapper-all {
    width: 90%;
    max-width: 400px;
    margin: 40px auto;
  }

  .first-half {
    width: 100%;
    padding: 16px 0px;
  }

  .field {
    width: 100%;
  }

  .bottom {
    width: 100%;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
  }

  .button-common {
    width: 100%;
  }

  .forgot-button {
    width: 100%;
    text-align: center;
  }

  .responsive-button {
    width: 100%;
    text-align: center;
  }

  .button-container-password {
    flex-direction: row;
    gap: 16px;
  }
}

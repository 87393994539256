// .home-container {
//   display: flex;
//   flex-direction: column;
// }

.main-card {
    width: 100%;
    height: 100vh;
    overflow-y: auto;
    padding-left: 240px;
    padding-top: 67px;
  }
  
  .card-wrapper {
    margin-left: 15px;
    margin-right: 15px;
    margin-top: 25px;
    margin-bottom: 25px;
    padding: 24px 16px;
    border-radius: 8px;
    gap: 32px;
    background: #FFFFFF;
    box-shadow: 0px 1px 3px 0px rgba(0, 0, 0, 0.30), 0px 4px 8px 3px rgba(0, 0, 0, 0.15);
    overflow-x: hidden;
  
  
    .card-header {
      display: flex;
      justify-content: space-between;
      font-weight: bold;
      line-height: 36px;
    }
    .profile {
      margin-top: 32px;
    }
    .remove-button-box {
      padding: 10px, 24px, 10px, 24px !important;
    }
    .remove-button {
      width: 160px;
      height: 40px;
      padding: 10px, 24px, 10px, 24px;
      border-radius: 100px;
      border: 1px solid #75777F;
      box-shadow: none;
      gap: 8px;
      font-size: 14px;
      font-weight: 500;
      line-height: 20px;
      letter-spacing: 0.10000000149011612px;
      text-align: center;
      color: #0059C7;
      background-color: #FFFFFF;
      text-decoration: none;
       &:hover {
        background-color: white;
        color: '#0059C7'
      }
    }
      
    .avatar {
      width: 160px;
      height: 160px;
      color: #FFFFFF;
    }
    .edit-button {
      border-radius: 100px;
      border: 1px solid #75777F;
      width: 73px;
      height: 40px;
      padding: 10px, 24px, 10px, 24px;
      gap: 8px;
      color:#0059C7;
      font-size: 14px;
      font-weight: 500;
      line-height: 20px;
      letter-spacing: 0.10000000149011612px;
      text-align: center;
    }
  
    .save-button {
      border-radius: 100px;
      border: 1px solid #75777F;
      width: 73px;
      height: 40px;
      padding: 10px, 24px, 10px, 24px;
      gap: 8px;
      color: #FFFFFF;
      background-color: #0059C7;
      font-size: 14px;
      font-weight: 500;
      line-height: 20px;
      letter-spacing: 0.10000000149011612px;
      text-align: center;
    }
    .card-header-about {
      font-size: 28px;
      font-weight: 400;
    }
  
    .form {
      padding-top: 24px;
      padding-left: 16px;
      width: 30%;
    }
    
    .card-header-stats {
      display: flex;
      justify-content: space-between;
      align-items: center;
      height: 40px;
      gap: 32px;
    }
  
    .card-header-data {
      height: 36px;
      font-size: 28px;
      font-weight: 400;
      line-height: 36px;
      letter-spacing: 0em;
      text-align: left;
      color: #000000;
    }
  
    .stats-row {
      width: 515px; 
      height: 40px; 
      padding: 8px 0px; 
      border: 0px 0px 1px 0px; 
      gap: 16px;
      color: #75777F;
      border-bottom: 1px solid #75777F;
    }
  
    .data-column {
      display: flex;
    }
  
    .data-title {
      width: 300px; 
      height: 24px;
      font-size: 16px;
      font-weight: 500;
      line-height: 24px;
      letter-spacing: 0.10000000149011612px;
      text-align: left;
      color: #000000;
    }
  
    .data-value {
      width: 58px;
      height: 24px;
      font-size: 16px;
      font-weight: 400;
      line-height: 24px;
      letter-spacing: 0.5px;
      text-align: left;
      color: #0059C7;
    }
  }
  
  .content-container {
    display: flex;
  }
  
  .main-content {
    flex: 1;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    width: 100%;
  }
  
  .topbar-container {
    width: 100%;
    background: #1b1b1e;
    font-size: 22px;
    font-weight: 400;
    line-height: 28px;
    letter-spacing: 0em;
    text-align: center;
  }
  
  
  .home-container {
    display: flex;
    flex-direction: column;
  }
  
  .content-container {
    display: flex;
  }
  
  .main-content {
    flex: 1;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    width: 100%;
  }
  .topbar-container {
    width: 100%;
    background: #1b1b1e;
    font-size: 22px;
    font-weight: 400;
    line-height: 28px;
    letter-spacing: 0em;
    text-align: center;
  }
  .table-container {
    width: 100%;
    height: 100%;
  }
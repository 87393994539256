.to_field {
	width: 210px;
	height: 56px;
	border: 1px solid #000000;
	gap: 0px;
	border-radius: 4px 0px 0px 0px;
}
.subject_field {
	width: 275px;
	height: 56px;
	gap: 0px;
	border-radius: 4px 0px 0px 0px;
	border: 1px 0px 0px 0px;

	border: 1px solid #000000;
}
.date_field {
	width: 162px;
	height: 56px;
	border: 1px solid #000000;
	padding: 16px 34px 16px 11px;
	gap: 10px;
	border-radius: 4px 0px 0px 0px;
	border: 1px 0px 0px 0px;
}
.time_field {
	width: 162px;
	height: 56px;
	border: 1px solid #000000;
	padding: 16px 34px 16px 11px;
	gap: 10px;
	border-radius: 4px 0px 0px 0px;
	border: 1px 0px 0px 0px;
}
.message_field {
	width: 1025px;
	gap: 0px;
	border-radius: 4px 0px 0px 0px;
	border: 1px 0px 0px 0px;
	border: 1px solid #000000;
}

.send_button {
	width: 226px;
	height: 59.84px;
	top: 948px;
	left: 268px;
	gap: 0px;
	border-radius: 10px;
	background: #0059c7;
	color: #ffffff;
	border: none;
}

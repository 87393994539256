.container {
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .bottom-password-requested {
    display: flex;
    width: 360px;
    height: fit-content;
    padding: 16px 4px;
    gap: 16px;
    align-items: center;
    justify-content: center;
    margin-top: -4px;
  }
  
  .wrapper-all-password-requested {
    width: 400px;
    height: 450px;
    top: 160px;
    left: 520px;
    border-radius: 12px;
    gap: 16px;
    justify-content: space-between;
    box-shadow: 0px 4px 4px 0px #0000004d;
    box-shadow: 0px 8px 12px 6px #00000026;
    margin: 80px auto;
    padding: 40px 30px 30px 30px;
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  
  .first-half-requested {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 382px;
  }
  
  .title-password-request {
    width: 57px;
    height: 85px;
   
    font-size: 32px;
    font-weight: 400;
    line-height: 40px;
    letter-spacing: 0em;
    text-align: center;
    color: #1b1b1e;
  }
  .title-body-password-request {
    width: 200px;
    height: 24px;
    font-size: 16px;
    font-weight: 500;
    line-height: 24px;
    letter-spacing: 0.10000000149011612px;
    text-align: center;
  }
  .text-support {
    margin-top: 10px;
    text-decoration: none;
    color: #0059C7;
    
  }
  
  
.flag-item-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.left-panel {
  padding: 20px;
  width: 289px;
  margin-left: 20px;
  color: #000000;
}

.report-heading {
  margin-bottom: 20px;
  margin-top: 10px;
  margin-left: 400px;

  font-size: 33px;
  font-weight: 500;
  line-height: 40px;
  letter-spacing: 0em;
  text-align: left;
  color: #000000;
  width: 315px;
  height: 46px;
  top: 237px;
}

.right-panel {
  padding: 12px;
  margin-left: 580px;
}

.flag-image {
  width: 111px;
  height: 111px;
}
.summary {
  text-align: center;
  width: 882px;
  height: 46px;
  top: 558px;
  left: 650px;

  font-size: 33px;
  font-weight: 500;
  line-height: 40px;
  letter-spacing: 0em;
  margin-left: 30px;
  color: #000000;
}
.summary-text {
  margin-left: 20px;
  color: #000000;

  font-size: 18px;
  font-weight: 400;
  line-height: 27px;
  letter-spacing: 0em;
  text-align: left;
  color: #000000;
}

.flag-container-style {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
}

.container {
  align-items: start;
}

.btn-status {
  width: 101px;
  height: 24px;
  padding: 10px, 24px, 10px, 24px;
  border-radius: 100px;
  border: 1px;
  gap: 8px;
  background: white;
  border: 1px solid #0059c7;
  color: #0059c7;
  margin-right: 10px;
}
.onButton {
  border-radius: 100px;
  background-color: transparent;
  width: 110px;
  border: 1px solid blue;
  height: 24px;
  text-transform: none;
  margin-right: 10px;
  background-color: #0059c7;
  font-family: Roboto;
  font-size: 16px;
  font-weight: 500;
  line-height: 24px;
  letter-spacing: 0.10000000149011612px;
  text-align: center;
  color: #ffffff;
  display: flex;
  justify-content: center;
  align-items: center;
}

.onButton:hover {
  border-radius: 10px;
  background-color: transparent;
  border: 1px solid black;
  width: 110px;
  border: 1px solid #75777f;
  height: 24px;
  text-transform: none;
  margin-right: 10px;
  background-color: #0059c7;
  color: #ffffff;
  display: flex;
  justify-content: center;
  align-items: center;
}

.offButton {
  border-radius: 100px;
  background-color: transparent;
  color: #0159c7;
  border: 1px solid black;
  width: 110px;
  border: 1px solid #75777f;
  height: 24px;
  text-transform: none;
  margin-right: 10px;
  font-family: Roboto;
  font-size: 16px;
  font-weight: 500;
  line-height: 24px;
  letter-spacing: 0.10000000149011612px;
  text-align: center;
  background-color: #ffffff;
  display: flex;
  justify-content: center;
  align-items: center;
}

.offButton:hover {
  border-radius: 10px;
  background-color: transparent;
  color: #0159c7;
  border: 1px solid black;
  width: 110px;
  border: 1px solid #75777f;
  height: 24px;
  text-transform: none;
  margin-right: 10px;
  background-color: #ffffff;
  display: flex;
  justify-content: center;
  align-items: center;
}

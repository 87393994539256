.custom-navbar {
  display: flex;
  min-width: 100%;
  width: 100vh;
  height: 64px;
  justify-content: space-between;
  align-items: center;
  background: #0059c7;
  box-shadow: 0px 2px 6px 2px #00000026;
  box-shadow: 0px 1px 2px 0px #0000004d;
  z-index: 1000;
}
.navbar-custom-brand {
 
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: 0.5px;
  color: #f2f0f4;
  align-items: center;
}

.navbar-avatar-left {
  width: 40px;
  height: 32px;
  border-radius: 100px;
  color: #d7e2ff;
  margin-right: 8px;
  margin-left: 10px;

}

.nav-custom-link {
  color: #dbe2f9;
  margin-right: 16px;
  text-decoration: none;
}
.navbar-custom-text {
  width: 40px;
  height: 32px;
  border-radius: 100px;
  color: #d7e2ff;
  text-align: left;
}
.right-nav {
  display: flex;
  align-items: center;
}

.left-nav {
  display: flex;
  align-items: center;
}
.navbar-text-uon{
font-size: 24px;
font-weight: 400;
line-height: 24px;
letter-spacing: 0.5px;
text-align: left;
color: #F2F0F4;
margin-left: 20px;
width: 51px;
}
@media (max-width: 767.98px) {
  .custom-navbar {
    padding: 0 8px;
    width: 100vh;
  }
}

.wrapper-card-update-password {
  width: 381px;
  height: 376px;
  top: 170px;
  left: 540px;
  border-radius: 12px;
  gap: 16px;
  box-shadow: 0px 4px 4px 0px #0000004d;
  box-shadow: 0px 8px 12px 6px #00000026;
  margin: 80px auto;
  padding: 40px 30px 30px 30px;
  background: #fdfbff;
}

.header-card-update-password {
  display: flex;
  align-items: center;
  gap: 4px;
  width: 304px;
  height: 24px;
}

.header-text-update-password {
  flex: 1;
  
  font-size: 18px;
  font-weight: 500;
  line-height: 24px;
  letter-spacing: 0.1px;
  color: #1b1b1e;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  margin-bottom: 20px;
}

.icon-update-password {
  width: 24px;
  height: 24px;
  padding: 2px;
  margin-bottom: 20px;
}
.card-field-update-password {
  display: flex;
  flex-direction: column;
  width: 320px;
  height: 70px;
  margin-top: 30px;
  gap: 16px;
  margin-left: 0;
}
.custom-input-update-password {
  width: 300px;
  height: 56px;
  padding: 0px 16px;
  border-radius: 4px;
  border: 1px solid #75777f;
  color: #75777f;
  font-size: 16px;
  line-height: 24px;
  background-color: transparent;
}
.card-button-container-update-password {
  width: 360px;
  height: 72px;
  padding: 16px;
  gap: 8px;
  margin-top: 20px;
}
.card-button-container-update-password {
  width: 340px;
  height: 72px;
  padding: 16px;
  gap: 8px;
  display: flex;
  justify-content: flex-end;
}

.custom-button-create-update-password {
  padding: 10px 24px 10px 24px;
  border-radius: 100px;
  gap: 10px;
  width: 90px;
  height: 40px;
  font-size: 14px;
  font-weight: 500;
  line-height: 20px;
  letter-spacing: 0.10000000149011612px;
  color: #ffffff;
  background-color: #0059c7;
  border: none;
  cursor: pointer;
}
.custom-button-back-update-password {
  width: 90px;
  height: 40px;
  padding: 10px 24px 10px 24px;
  border-radius: 100px;
  border: 1px;
  gap: 8px;
  background: linear-gradient(0deg, #fdfbff, #fdfbff);
  color: #0059c7;
  border: 1px solid #75777f;
  font-size: 14px;
  font-weight: 500;
  line-height: 20px;
  letter-spacing: 0.10000000149011612px;
  text-align: center;
}
.error-message {
  color: red;
  font-size: 12px;
  margin-top: 2px;
  text-align: center;
}
.links {
  text-decoration: none;
}

.password-field {
  position: relative;

  input[type="password"] {
    padding-right: 40px;
  }

  .password-toggle-icon {
    position: absolute;
    top: 50%;
    right: 5px;
    transform: translateY(-50%);
    cursor: pointer;
  }
}


.blur-background {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: #575E71;

  backdrop-filter: blur(5px); 
  z-index: 1000; 
  display: flex;
  justify-content: center;
  align-items: center;
}

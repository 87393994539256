.top-bar {
  display: flex;
  align-items: center;
  gap: 16px;
  width: 100%;
  height: 64px;
  padding-left: 10px;
  background-color: #E9EEFB;
  // background: linear-gradient(0deg, #fdfbff, #fdfbff),
  //   linear-gradient(0deg, rgba(0, 89, 199, 0.08), rgba(0, 89, 199, 0.08));
  box-shadow: 0px 2px 6px 2px #00000026;
  box-shadow: 0px 1px 2px 0px #0000004d;
}

.top-bar-heading {
  width: 570px;
  height: 28px;
  font-size: 22px;
  font-weight: 400;
  line-height: 28px;
  letter-spacing: 0em;
  text-align: left;
  justify-content: start;
  color: #1b1b1e;
}
.top-bar-button {
  width: 119px;
  height: 40px;
  border-radius: 100px;
  gap: 10px;
  color: #0059c7;
  border: 1px solid #0059c7;
  font-size: 14px;
  font-weight: 500;
  line-height: 20px;
  letter-spacing: 0.1px;
  text-align: center;
  background-color: #0059c7;
  color: #ffffff;
}

.top-bar-save-button{
  width: 79px;
  height: 40px;
  border-radius: 100px;
  gap: 10px;
  color: #0059c7;
  border: 1px solid #0059c7;
  font-size: 14px;
  font-weight: 500;
  line-height: 20px;
  letter-spacing: 0.1px;
  text-align: center;
  background-color: #0059c7;
  color: #ffffff;
}
.top-bar-button-modify {
  width: 174px;
  height: 40px;
  border-radius: 100px;
  gap: 10px;
  color: #0059c7;
  border: 1px solid #0059c7;
  font-size: 14px;
  font-weight: 500;
  line-height: 20px;
  letter-spacing: 0.1px;
  text-align: center;
  background-color: #0059c7;
  color: #ffffff;
}
.top-bar-button-link {
  text-decoration: none;
}
.top-bar-buttons {
  gap: 10px;
}
.top-bar-button-update {
  width: 150px;
  margin-right: 15px;
}
.top-bar-button-add {
  margin-left: auto;
}


@media (max-width: 1830px) {
  .colStyling{
    margin-left: -35px;
  }
}

@media (max-width: 1300px) {
  .colStyling{
    margin-left: -75px;
  }
}

@media (max-width: 1200px) {
  .colStyling{
    margin-left: -35px;
  }
}

@media (max-width: 1040px) {
  .colStyling{
    margin-left: -65px;
  }
}

@media (max-width: 1300px) {
  .col2Styling{
    margin-right: 25px;
  }
}



@media (max-width: 1170px) {
  .col2Styling{
    margin-right: 30px;
  }
}

@media (max-width: 1050px) {
  .col2Styling{
    margin-right: 50px;
  }
}


@media (max-width: 950px) {
  .col2Styling{
    margin-right: 70px;
  }
}
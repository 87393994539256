.table-container {
  width: 100%;
  height: fit-content;
  margin-top: 10px;
  gap: 22px;
  .btn-table {
    width: 129px;
    height: 40px;
    padding: 8px 12px;
    border: 0px 0px 1px 0px;
    border-bottom: 1px solid #E4E2E6;
    display: flex;
    align-items: center;
    gap: 8px;
    justify-content: center;
    background-color: transparent;
    color: #E4E2E6;
    &:hover {
      background-color: rgba(0, 0, 0, 0.05);
    }
  }
}
.btn-banned {
  width: 96px;
  height: 24px;
  padding: 10px 24px;
  border-radius: 100px;
  gap: 8px;
  color: #7c0303ab;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: transparent;
  &:hover {
    background-color: rgba(124, 3, 3, 0.2);
  }
}


.filter-button-top{
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
}


.btn-filter{
  width: 106px;
  height: 40px;
  border-radius: 100px;
  gap: 8px;
  background: #ffffff;
  border: 1px solid #75777f;
  color: #0059c7;
  font-size: 14px;
  font-weight: 500;
  line-height: 20px;
  letter-spacing: 0.10000000149011612px;
  text-align: center;
}

.MuiTablePagination-selectLabel{
  padding-top: 10px;
}
.colunm-header .MuiDataGrid-columnHeaderTitleContainer {
  display: flex;
  justify-content: space-between;
}
.MuiTablePagination-displayedRows{
  padding-top: 15px;
}

.MuiDataGrid-panel{
  padding-left: 1030px;
}

@media (max-width: 1440px) {
  .MuiDataGrid-panel{
    padding-left: 500px;
  } 
}


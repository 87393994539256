.content-container {
	display: flex;
	flex-direction: row;
}

.main-content {
	flex-grow: 1;
}

.topbar-container {
	margin-bottom: 20px;
}

.message-container {
	padding: 20px;
}

.save_button {
	border: none;
	border-radius: 10px;
	font-family: Roboto;
	font-size: 16px;
	font-weight: 400;
	line-height: 24px;
	letter-spacing: 0.5px;
	text-align: center;
	background: #0059c7;
	color: #ffffff;
	width: 146px;
	height: 56px;
	margin-right: 8px;
}
.delete_button {
	top: 267px;
	left: 1235px;
	gap: 0px;
	border-radius: 10px;
	background: #ba1b1b;
	width: 83px;
	height: 56px;
	top: 273.79px;
	left: 1237.84px;
	color: #ffffff;
	border: none;
}
.to_field {
	width: 210px;
	height: 56px;
	border: 1px solid #000000;
	gap: 0px;
	border-radius: 4px 0px 0px 0px;
}
.subject_field {
	width: 275px;
	height: 56px;
	gap: 0px;
	border-radius: 4px 0px 0px 0px;
	border: 1px 0px 0px 0px;
	border: 1px solid #000000;
}
.date_field {
	width: 162px;
	height: 56px;
	border: 1px solid #000000;
	padding: 16px 34px 16px 11px;
	gap: 10px;
	border-radius: 4px 0px 0px 0px;
	border: 1px 0px 0px 0px;
}
.time_field {
	width: 162px;
	height: 56px;
	border: 1px solid #000000;
	padding: 16px 34px 16px 11px;
	gap: 10px;
	border-radius: 4px 0px 0px 0px;
	border: 1px 0px 0px 0px;
}
.message_field {
	width: 1025px;
	gap: 0px;
	border-radius: 4px 0px 0px 0px;
	border: 1px 0px 0px 0px;
	border: 1px solid #000000;
}
.TextField-root {
	width: 210px;
	height: 56px;
	gap: 0px;
	border-radius: 4px 0px 0px 0px;
	border: 1px solid #000000;
}

.TextField-root > .MuiOutlinedInput-root {
	border-radius: 4px 0px 0px 0px;
	border: 1px solid #000000;
}

.TextField-root > .MuiOutlinedInput-root.Mui-focused {
	border-color: #000000;
}

.TextField-root > .MuiInputLabel-root {
	top: 267px;
	left: 293px;
}

.TextField-root > .MuiInputLabel-root.MuiInputLabel-shrink {
	top: 0;
	left: 0;
}

.TextField-root > .MuiInputLabel-root + .MuiInput-formControl {
	margin-top: 0;
}

.TextField-root > .MuiInputLabel-root.MuiInputLabel-outlined {
	transform: translate(14px, 20px) scale(1);
}

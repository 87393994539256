@import "common";

input:-webkit-autofill {
  -webkit-box-shadow: 0 0 0 30px white inset !important;
}

.custom-dropdown-button {
  color: white;
  background-color: #0159c7;
  border: none;
  gap: 8px;
  font-size: 14px;
  font-weight: 500;
  line-height: 20px;
  letter-spacing: 0.10000000149011612px;
  text-align: center;
}
.arrow-icon {
  color: #44464e;
  top: 7.5px;
}

.key-icon {
  width: 20px;
  height: 16px;
  top: 4px;
  left: 2px;
  color: #1f1f1f;
}

.visibility-icon {
 
  color: #44464e;
}
.visibility-off-icon {
  color: #44464e;
  
}


@media screen and (min-width: 280px) {
  .bottom {
    margin-top: -12px;
  }
}

@media screen and (min-width: 768px) {
  .bottom {
    margin-top: 0px;
  }
}


@import "common";

.back-button {
  margin-left: -10px;
}

.backButton {
  width: 80px;
  height: 40px;
  padding: 10px, 24px, 10px, 24px;
  border-radius: 100px;
  gap: 10px;
  background-color: #0059c7;
  color: white;
  border: none;
  font-weight: 700;
  padding-top: 3px;
}

.main-card {
  width: 100%;
  height: 100vh;
  overflow-y: auto;
  padding-left: 240px;
  padding-top: 67px;
}

.short-card-wrapper {
  margin-left: 15px;
  margin-right: 625px;
  margin-top: 25px;
  margin-bottom: 25px;
  padding: 24px 16px;
  border-radius: 8px;
  gap: 32px;
  background: #ffffff;
  box-shadow: 0px 1px 3px 0px rgba(0, 0, 0, 0.3),
    0px 4px 8px 3px rgba(0, 0, 0, 0.15);
  overflow-x: hidden;
}

.dating-access {
  color: black;
  font-weight: 400;
  font-size: 12px;
  line-height: 1.4375em;
  letter-spacing: 0.00938em;
}

.edit-dating-access {
  display: flex;
  justify-content: space-between;
  color: #44464e;
  width: 65%;
  margin: 2.5% 0px;
}

.toggle-box {
  display: flex;
  align-items: center;
}

.switch {
  position: relative;
  display: inline-block;
  width: 34px;
  height: 16px;
}

.switch input {
  opacity: 0;
  width: 0;
  height: 0;
}

.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ccc;
  transition: 0.4s;
  border-radius: 34px;
}

.slider:before {
  position: absolute;
  content: "";
  height: 11px;
  width: 11px;
  left: 3px;
  bottom: 2.5px;
  background-color: white;
  transition: 0.4s;
  border-radius: 50%;
}

input:checked + .slider {
  background-color: black;
}

input:checked + .slider:before {
  transform: translateX(14px);
}

.slider.round {
  border-radius: 34px;
  margin-left: 2.5px;
}

.dating-access {
  color: black;
  font-weight: 400;
  font-size: 12px;
  line-height: 1.4375em;
  letter-spacing: 0.00938em;
}

.edit-dating-access {
  display: flex;
  justify-content: space-between;
  color: #44464e;
  width: 65%;
  margin: 2.5% 0px;
}

.toggle-box {
  display: flex;
  align-items: center;
}

.switch {
  position: relative;
  display: inline-block;
  width: 34px;
  height: 16px;
}

.switch input {
  opacity: 0;
  width: 0;
  height: 0;
}

.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ccc;
  transition: 0.4s;
  border-radius: 34px;
}

.slider:before {
  position: absolute;
  content: "";
  height: 11px;
  width: 11px;
  left: 3px;
  bottom: 2.5px;
  background-color: white;
  transition: 0.4s;
  border-radius: 50%;
}

input:checked + .slider {
  background-color: black;
}

input:checked + .slider:before {
  transform: translateX(14px);
}

.slider.round {
  border-radius: 34px;
  margin-left: 2.5px;
}

.dating-access {
  color: black;
  font-weight: 400;
  font-size: 12px;
  line-height: 1.4375em;
  letter-spacing: 0.00938em;
}

.edit-dating-access {
  display: flex;
  justify-content: space-between;
  color: #44464e;
  width: 65%;
  margin: 8% 0px;
}

.toggle-box {
  display: flex;
  align-items: center;
}

.switch {
  position: relative;
  display: inline-block;
  width: 34px;
  height: 16px;
}

.switch input {
  opacity: 0;
  width: 0;
  height: 0;
}

.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ccc;
  transition: 0.4s;
  border-radius: 34px;
}

.slider:before {
  position: absolute;
  content: "";
  height: 11px;
  width: 11px;
  left: 3px;
  bottom: 2.5px;
  background-color: white;
  transition: 0.4s;
  border-radius: 50%;
}

input:checked + .slider {
  background-color: black;
}

input:checked + .slider:before {
  transform: translateX(14px);
}

.slider.round {
  border-radius: 34px;
  margin-left: 2.5px;
}

.card-wrapper {
  margin-left: 15px;
  margin-right: 15px;
  margin-top: 25px;
  margin-bottom: 25px;
  padding: 24px 16px;
  border-radius: 8px;
  gap: 32px;
  background: #ffffff;
  box-shadow: 0px 1px 3px 0px rgba(0, 0, 0, 0.3),
    0px 4px 8px 3px rgba(0, 0, 0, 0.15);
  overflow-x: hidden;

  .card-header {
    display: flex;
    justify-content: space-between;
    font-weight: bold;
    line-height: 36px;
  }
  .profile {
    margin-top: 32px;
  }
  .remove-button-box {
    padding: 10px, 24px, 10px, 24px !important;
  }
  .remove-button {
    width: 160px;
    height: 40px;
    padding: 10px, 24px, 10px, 24px;
    border-radius: 100px;
    border: 1px solid #75777f;
    box-shadow: none;
    gap: 8px;
    font-size: 14px;
    font-weight: 500;
    line-height: 20px;
    letter-spacing: 0.10000000149011612px;
    text-align: center;
    color: #0059c7;
    background-color: #ffffff;
    text-decoration: none;
    &:hover {
      background-color: white;
      color: "#0059C7";
    }
  }

  .avatar {
    width: 160px;
    height: 160px;
    color: #ffffff;
  }
  .edit-button {
    text-transform: none;
    border-radius: 100px;
    border: 1px solid #75777f;
    width: 73px;
    height: 40px;
    padding: 10px, 24px, 10px, 24px;
    gap: 8px;
    color: #0059c7;
    font-size: 14px;
    font-weight: 500;
    line-height: 20px;
    letter-spacing: 0.10000000149011612px;
    text-align: center;
  }

  .save-button {
    text-transform: none;
    border-radius: 100px;
    border: 1px solid #75777f;
    width: 73px;
    height: 40px;
    padding: 10px, 24px, 10px, 24px;
    gap: 8px;
    color: #ffffff;
    background-color: #0059c7;
    font-size: 14px;
    font-weight: 500;
    line-height: 20px;
    letter-spacing: 0.10000000149011612px;
    text-align: center;
  }
  .card-header-about {
    font-size: 28px;
    font-weight: 400;
  }

  .card-header-question-bank {
    font-size: 18px;
    font-weight: 400;
  }

  .form {
    padding-top: 24px;
    padding-left: 16px;
    width: 30%;
  }

  .card-header-stats {
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 40px;
    gap: 32px;
  }

  .card-header-data {
    height: 36px;
    font-size: 28px;
    font-weight: 400;
    line-height: 36px;
    letter-spacing: 0em;
    text-align: left;
    color: #000000;
  }

  .stats-row {
    width: 515px;
    height: 40px;
    padding: 8px 0px;
    border: 0px 0px 1px 0px;
    gap: 16px;
    color: #75777f;
    border-bottom: 1px solid #75777f;
  }

  .data-column {
    display: flex;
  }

  .data-title {
    width: 300px;
    height: 24px;
    font-size: 16px;
    font-weight: 500;
    line-height: 24px;
    letter-spacing: 0.10000000149011612px;
    text-align: left;
    color: #000000;
  }

  .data-value {
    width: 58px;
    height: 24px;
    font-size: 16px;
    font-weight: 400;
    line-height: 24px;
    letter-spacing: 0.5px;
    text-align: left;
    color: #0059c7;
  }
}

.content-container {
  display: flex;
}

.main-content {
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 100%;
  padding-left: 242px;
  padding-top: 66px;
}

.topbar-container {
  width: 100%;
  background: #1b1b1e;
  font-size: 22px;
  font-weight: 400;
  line-height: 28px;
  letter-spacing: 0em;
  text-align: center;
}

.home-container {
  display: flex;
  flex-direction: column;
}

.content-container {
  display: flex;
}

.main-content {
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 100%;
}
.topbar-container {
  width: 100%;
  background: #1b1b1e;
  font-size: 22px;
  font-weight: 400;
  line-height: 28px;
  letter-spacing: 0em;
  text-align: center;
}
.table-container {
  width: 100%;
  height: 100%;
}

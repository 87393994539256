.search-and-dropdown-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
}

.search-field {
  flex: 1;
  margin-right: 10px;
}

.enum-field-container {
  flex: 1;
  margin-left: 10px;
}

.enum-field {
  display: flex;
  align-items: center;
  position: relative;
}

.dropdown-icon {
  margin-left: 8px;
  cursor: pointer;
  color: #888;
}
